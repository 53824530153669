import Joi from 'joi'

type AddressFields = keyof Omit<
  Types.AddressShopHelp,
  'company' | 'email' | 'longitude' | 'latitude' | 'province_code' | 'country' | 'country_code'
>

function phoneValidator(value: string, helper: any) {
  const temp = value.replace(/\(|\)|-| /g, '')
  const length = temp.length

  return length !== 10 ? helper.message('"Phone number" length must be 10 characters long') : value
}

// const emailSchema = Joi.string()
//   .trim()
//   .email({ tlds: { allow: false } })
//   .label('Email')
const firstNameSchema = Joi.string().trim().label('First name')
const lastNameSchema = Joi.string().trim().label('Last name')
const nameSchema = Joi.string().trim().label('Full name')
const addressLineSchema = Joi.string().trim().label('Address')
const address2LineSchema = Joi.string().trim().allow('').label('Address2')
const apartmentSchema = Joi.string().trim().allow('').label('Apartment')
const zipSchema = Joi.string().trim().label('Postal code')
const citySchema = Joi.string().trim().label('City')
const provinceSchema = Joi.string().trim().allow('').label('Province')
// const stateSchema = Joi.string().trim().label('State')
// const countryCodeSchema = Joi.string().default('US').label('Country code')
// const countrySchema = Joi.string().default('United States').label('Country')
const phoneSchema = Joi.string().trim().label('Phone number').custom(phoneValidator)

const addressSchema = Joi.object<Types.AddressShopHelp>({
  first_name: nameSchema.required(),
  last_name: nameSchema.required(),
  address1: addressLineSchema.required(),
  address2: address2LineSchema,
  apartment: apartmentSchema,
  zip: zipSchema.required(),
  city: citySchema.required(),
  province: provinceSchema.required(),
  phone: phoneSchema.required()
})

export async function validateAddressShopHelpField(field: AddressFields, value: any) {
  const schemas = {
    first_name: firstNameSchema.required(),
    last_name: lastNameSchema.required(),
    address1: addressLineSchema.required(),
    address2: address2LineSchema,
    apartment: apartmentSchema,
    zip: zipSchema.required(),
    city: citySchema.required(),
    province: provinceSchema.required(),
    phone: phoneSchema.required()
  }
  const schema = schemas[field]

  if (schema) {
    return await schema.validateAsync(value)
  } else {
    throw new Error('Address schema not found!')
  }
}

export async function validateAddressShopHelp(
  address: Types.AddressShopHelp
): Promise<Types.AddressShopHelp> {
  try {
    const options: Joi.AsyncValidationOptions = { abortEarly: false, allowUnknown: true }
    return await addressSchema.validateAsync(address, options)
  } catch (err: any) {
    if (err.details && err.details.length) {
      const addressErrors: Types.AddressShopHelp = {}
      err.details.forEach((e: any) => {
        // @ts-ignore
        addressErrors[e.context.key] = e.message
      })
      err.addressErrors = addressErrors
    }

    throw err
  }
}
